.footer_main {
    background-color:#092C5B;
    padding-top: 2%;
}
.quick_links {
    display: flex;
    flex-direction: column;
}
.quick_links h5 {
    color: #C75934;
}
.quick_links a {
    color: #fff;
    text-decoration: none;
    padding: 3px;
    width: fit-content;
}
.quick_links a:hover {
    color: #7c3535;
    text-decoration: none;
}
.footer_services {
    display: flex;
    flex-direction: column;
}
.footer_services h5 {
    padding-top: 5px;
    color: #C75934;
}
.footer_services a {
    color: #fff;
    text-decoration: none;
    padding: 3px;
    width: fit-content;
}
.footer_services a:hover {
    color: #7c3535;
    text-decoration: none;
}
.other_links {
    display: flex;
    flex-direction: column;
}
.other_links h5 {
    padding-top: 5px;
    color: #C75934;
}
.other_links a {
    color: #fff;
    text-decoration: none;
    padding: 3px;
    width: fit-content;
}
.other_links a:hover {
    color: #7c3535;
    text-decoration: none;
}
.map_section {
    display: flex;
    flex-direction: column;
}
.map_section iframe {
    width: 100%;
    height: 100%;
}
.social_media_links {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding-top: 2%;
}
.social_media_links a:hover {
    color: #7c3535 !important;
}
.my_hr {
    width: 100%;
    height: 2px;
    background-color: #fff;
    margin-top: 2px;
}
.copy_right {
    color: #fff;
    align-items: center;
    font-size: 12px;
}
.copy_right a {
    color: #fff;
}
.onviro {
    text-align: end;
    color: #fff;
    font-size: 12px;
}
.onviro a {
    color: #fff;
    text-decoration: none;
}
.onviro a:hover {
    color: #7c3535;
    text-decoration: none;
}
.flight{
border-radius: 7px;
    position: fixed;
    right: 0;
    background: #C75934;
    padding: 7px;
    bottom: 35%;
    z-index: 10000;
    /* transform: rotate(270deg);    */
}
.flight p {
    margin-top: 0;
    margin-bottom: 0rem;
    color: #fff;
}
.flight svg{
 height: 20px;
 width: 20px;
     
}
