.spinner_main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 700px;
}
.spinner_main img {
  width: 280px;
  height: 200px;
  object-fit: contain;
}
.spinner_main {
  animation: spin 5s;
  animation-duration: 5s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}
@keyframes spin {
  0% {
    transform: scale(0.8, 0.8);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(0.8, 0.8);
  }
}