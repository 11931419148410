.address_col {
    margin-top: 2%;
}
.appointment_content {
    margin-top: 2%;
}
.contact_address {
    margin: 0 auto;
    border: 0.2px solid #cfcfcf;
    padding-left: 10px;
    padding-top: 10px;
    border-radius: 6px;
}
.contact_address h4 {
    font-weight: 600;
    color: #1174BC;
}
.individual_details h4 {
    font-weight: 600;
    color: #1174BC;
}
.contact_cols {
    padding-left: 3%;
}
.contact_individuals {
    display: flex;
    gap: 10px;
}
.map_contact {
    margin-top: 2%;
    margin-bottom: 2%;
}
.button_cols {
    margin-top: 2%;
    text-align: center;
}
.add_education {
    margin-top: 3%;
    margin-bottom: 2%;
}