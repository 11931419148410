/* .banner_below {
    margin-top: -1%;
    padding: 0;
} */

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,400;0,500;0,700;1,500;1,700&display=swap');
 *{
    font-family: 'Nunito Sans', sans-serif;
  }
.nav_global {
    position: absolute;
    background-color: #fff;
    z-index: 2;
    width: 100%;
}
.mission {
    background-color: #C75934;
}
.vision {
    background-color: Rgb(26 115 186);
}
.goal {
    background-color: #C75934;
}
.main_logo {
   margin-top:3%;
}
.mvg_main {
    padding-top: 3%;
    color: #fff;
    text-align: justify;
    padding-right: 20px;
}
.functions {
    margin-top: 3%;
}
.functions_column {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.function_section {
    border: 0.5px solid #c75934;
    width: 80%;
    border-radius: 10px;
    text-align: center;
    padding-bottom: 5%;
}
.circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #1174bcb0;
    margin: 0 auto;
    margin-top: 5%;
    margin-bottom: 5%;
    position: relative;
}
.circle img {
    width: 108px;
    height: 108px;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 50%;
}
.about_us {
    margin-top: 3%;
}
.index_about_img img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.index_about_content {
    padding-left: 3%;
}
.index_about_content h6 {
    color: #585756;
}
.index_about_content h3 {
    color: #c75934;
    text-transform: uppercase;
    font-weight: bold;
}
.index_about_content p {
    color: #1f1e1e;
    text-align: justify;
}
.after_line {
    width: 30%;
    height: 3px;
    background-color: #1174BC;
    margin-bottom:2%;
}
.study_abroad {
    margin-top: 3%;
    background: url(../images//studyabroadbackground.png) no-repeat;
    background-size: 100% 100%;
    padding-bottom: 5%;
}

.study_abroad h3 {
    color: #1174BC;
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
}
.after_line_abroad {
    width: 10%;
    height: 3px;
    background-color: #c75934;
    margin-bottom:2% !important;
    margin: 0 auto;
}
.index_services {
    margin-top: 3%;
}
.services_content {
    padding-left: 10px;
}
.services_content h4 {
    color: #1174BC;
    text-transform: uppercase;
    font-weight: bold;
}
.services_content p {
    color: #585756;
    text-align: justify;
    padding: 5px;
}

.index_associations {
    margin-top: 3%;
    background: url(../images/associatedbanner.png) no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    padding-bottom: 2%;
}
.associations_head {
    margin-top: 2%;
    color: #FFFFFF;
    text-align: center;
}
.after_line_associations {
    width: 25%;
    height: 3px;
    background-color: #c75934;
    margin-bottom:2% !important;
    margin: 0 auto;
}
.index_testimonials {
    margin-top:3%;
    text-align: center;
}
.testimonials_head {
    text-align: center;
}
.index_testimonials .about_us {
    height: 350px;
}
.testimonials_head h3 {
    color: #1174BC;
}
.after_line_testimonials {
    width: 25%;
    height: 3px;
    background-color: #c75934;
    margin-bottom:2% !important;
    margin: 0 auto;
} 
.news_main {
   margin-top: 3%;
}
.news_header {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.news_header h3 {
    color: #1174BC;
    margin: 0;
}
.after_line_news {
    width: 15%;
    height: 3px;
    background-color: #c75934;
    margin-bottom:2% !important;
    margin-left: 44%;
    margin-top: 5px;
}
.news_main h6 {
    text-align: center;
    color: #585756;
    font-weight: 600;
}
.events_header {
    text-align: left;
}
.events_header h4 {
    color: #1174BC;
    margin: 0;
}
.after_line_events {
    width: 10%;
    height: 3px;
    background-color: #c75934;
    margin-bottom:2% !important;
    margin-top: 2px;
}
