.banner img {
    background-size: 100%;
    height: 410px;
    width: 100%;
    object-fit: cover;
}

.banner {
    position: relative;
    width: 100%;
}

.banner::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.banner h4 {
    position: absolute;
    top: 50%;
    left: 8%;
    color: #fff;
    z-index: 4;
}