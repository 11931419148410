.apply_button{
    background-color: #C75934;
    border: none;
    padding: 7px 20px;
    border-radius: 5px;
    margin-left: 20px;
    color: #fff;  
    border: 1px solid #c75934;
}
.apply_button:hover {
    background-color: #ffffff;
    color: #c75934;
    border: 1px solid #c75934;
    transition: ease all 0.5s;
}

.learn_more {
    text-align: center;
}
.learn_more button {
    background-color: #C75934;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    width: 70%;
    border: 1px solid #c75934;
    transition: ease all 0.5s;
}
.learn_more button:hover {
    background-color: #ffffff;
    color: #c75934;
    border: 1px solid #c75934;
}
.learn_more a {
    background-color: #C75934;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    width: 70%;
    border: 1px solid #c75934;
    transition: ease all 0.5s;
    text-decoration: none;
}
.learn_more a:hover {
    background-color: #ffffff;
    color: #c75934;
    border: 1px solid #c75934;
}
.read_more {
    background-color: #1174BC;
    border: none;
    padding: 7px 20px;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #1174BC;
    transition: ease all 0.5s;
}
.read_more a {
    text-decoration: none !important;
    color: #fff;
}
.read_more:hover {
    background-color: #ffffff;
    color: #1174BC;
}
.read_more a:hover {
    color: #1174BC;
}
.callback_form button {
    width: 15%;
    height: 40px;
    border: none;
    background-color: #c75934;
    color: #fff;
    border-radius: 5px;

}
.callback_form button:hover {
    background-color: #1174BC;
    border: none;
    padding: 7px 20px;
    border-radius: 5px;
    color: #fff;
    border: 1px solid #ffffff;
    transition: ease all 0.5s;

}
.enroll_button {
    text-align: center;
    padding-bottom: 10%;
}
.enroll_button button{
    background-color: #C75934;
    border: none;
    padding: 3px 10px;
    color: #fff;
    transition: ease all 0.5s;
    margin-top: 4%;
}
.enroll_button button a {
    text-decoration: none;
    color: #fff;
}

.enroll_button button:hover {
    background-color: #ffffff;
    background-color: #1174BC;
    transition: ease all 0.5s;
}
