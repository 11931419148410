.abroads_banner {
    position: relative;

}
.abroads_banner  img{
    width: 100%;
    height: 410px;
    object-fit: cover;
}
.abroads_banner::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 410px;
    background: rgba(0,0,0,0.4);
}
.banner_above {
    position: absolute;
    top: 50%;
    left: 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.banner_above img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    z-index: 4;
}
.banner_above h5 {
    color: #fff;
    z-index: 4;
}
.overview_abroad h4{
   padding: 5px;
    color: #1174BC;
}
.overview_abroad p {
    padding-left: 10px;
}
.basic {
    padding-top: 1%;
    background-color: #F6FBFF;
}
.basic_info_main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 10px;
    color: #C75934;
}
.basic_info_main h5 {
    margin: 0;
}
.information {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    padding: 10px;
}
.information_main {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.information_main h5{
    text-align: center;
}
.tabs_main {
    margin-top: 2%;
}
.react-tabs__tab-list {
    width: 100%;
    background-color: #1174BC;
}

.react-tabs__tab {
    margin-top: -1px;
    padding: 15px !important;
    color: #fff;
    text-align: center;
    border: none !important;
}
.react-tabs__tab--selected {
    background-color: #C75934 !important;
    border: none !important;
    color: #fff !important;
    border-radius: 0px !important;
    height: 54px;
}
.tabs_main p {
    padding: 10px;
}
.description_main {
    margin-top: 2%;
    text-align: justify;
}
.universities_main {
    margin-top: 2% !important;
    background: #fff;
    margin-bottom: 20px;
}
.university_head {
    text-align: center;
}
.university_head h4 {
    color: #1174BC;
}
.after_line_university {
    width:28%;
    border-bottom: 2px solid #C75934;
    margin:0 auto;
}

.open {
    display: block;
  }
  .close {
    display: none;
  }