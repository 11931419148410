.preparation_banner {
    position: relative;
    width: 100%;
}
.preparation_banner::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}
.preparation_banner img {
    height: 410px;
    width: 100%;
}
.preparation_banner h4 {
    position: absolute;
    top: 50%;
    left: 8%;
    color: #fff;
    z-index: 4;
}  
.preparation_content {
    margin-top: 2%;
    text-align: justify;
} 
.apply_preparation {
    margin-top:-10%;
    z-index: 4;
}