.created_at {
    color: dimgray;
    font-size: 15px;
    margin-top: 4px;
}

.individual_info {
    padding: 10px;
}
.individual_image img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.individual {
    margin-bottom: 3%;
}