.owl-carousel .item img{
    height: 150px;
    height: 150px;
}
button.owl-next, button.owl-prev{
    background-color: #1174BC;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    display: none;
}
.item {
    display: -ms-grid;
  
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-column-align: center;
    justify-items: center;
    width: 100%;
    height: auto;
    -webkit-transition: -webkit-transform .5s ease-in;
    transition: -webkit-transform .5s ease-in;
    transition: transform .5s ease-in;
    transition: transform .5s ease-in, -webkit-transform .5s ease-in;
    margin: 8px auto;
}

.item img {
    max-width: 100%;
  height: 101px;
    -webkit-filter: opacity(50%);
    filter: opacity(90%);
    width: 100%;
     
    object-fit: contain;
}