

nav.navbar {
  position: absolute;
  top: 0;
  width: 90%;
  text-align: center;
  left: 5%;
  background-color: #1174BC !important;
  padding: 10px;
  z-index: 2;
}
.offcanvas-body{
  background-color: #1174BC;
}
a.nav-link {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  padding-top: 5px;
  border-radius: 5px;
}

a.nav-link:hover {
  color: #eb8867 !important;
}

a.nav-link.active {
  color: #eb8867 !important;
}
.dropdown.active {
  background-color: #1174BC !important;
  border: none;
  padding: 7px 20px;
  border-radius: 5px;
  margin-left: 20px;
  color: #fff;
}
a#collasible-nav-dropdown.active {
  color: #eb8867 !important;
  font-size: 18px;
  font-weight: 600;
  padding-top: 5px;
  border-radius: 5px;
}
.nav-link:focus, .nav-link:hover {
  color: #eb8867 !important;
}

