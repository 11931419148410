.services_head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 20px;
}
.after_line_services {
    width: 13%;
    height: 3px;
    background-color: #c75934;
    margin-bottom:2% !important;
    margin: 5px 46%;
}
.services_motto {
    text-align: center;
    color: #585756;
    font-size: 18px;
    font-weight: 500;
}
.services_key {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 3%;
}

.services_image {
  
    text-align: center;
}
.services_image img {
    width: 100% !important;
    height: 200px;
    object-fit: contain;
}