.carousel_main {
  height:800px;
  position: relative;

}

.carousel_main img {
  width: 100%;
  height:800px;

}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}

.slick-next {
  right: 3% !important;
  z-index: 1;
}

.slick-prev::before {
  font-size: 45px;
  color: #6b99e9;
}

.slick-next::before {
  font-size: 45px;
  color: #6b99e9;
}

.slick-dots {
  bottom: 30px;
}

.slick-dots li.slick-active button:before {
  color: #d61717;
}

.slick-dots li button:before {
  font-size: 16px;
  color: #6b99e9;
}

