.card_cols a {
    text-decoration: none;
}
.news_card {
    width: 100%;
    height: fit-content;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    margin-bottom: 20px;
    margin-top: 4%;
}
.news_card:hover {
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    transform: scale(1.01)
}
.news_card img {
    width: 100%;
    height: 200px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.news_card img::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.9);
}
.image_section {
    position: relative;
}
.image_section p {
    position: absolute;
    top: 85%;
    color: #fff;
    font-size: 14px;
    background-color: #c75934;
    width: 60%;
    padding: 5px;
    padding-left: 20px;
}
.image_section p::after {
    content: '';
    position: absolute;
    left: 99.5%;
    top: 0.5%;
    width: 0;
    height: 0;
    border-left: 32.5px solid transparent;
    border-right: 0px solid transparent;
    border-top: 32px solid #c75934;
    transform: rotate(271deg);
}
.news_card h6{
    margin: 0;
    padding: 0;
    font-weight: 700 !important;
}
.news_card h6 p{
    margin: 0;
    padding: 0;
    font-weight: 700;
}
.news_card h6:hover {
    color: #c75934 !important;
     color: #fff;
     transition: 0.5s;
 }
.news_card a h6 p:hover {
   color: #c75934 !important;
    color: #fff;
    transition: 0.5s;
}
.news_card a{
    text-decoration: none;
}
.news_card h6 {
    color: #585756;
    padding: 10px;
    padding-left: 20px;
    font-weight: 600;
    width: 100%;
}
