.gallery_topic {
    margin-top: 2%;
    text-align: center;
}
.gallery_topic h4 {
    color: #1174bc;
}
.gallery_topic span {
    color: #c75934;
}
.after_line_gallery {
    width: 10%;
    height: 3px;
    background-color: #c75934;
    margin-bottom:2% !important;
    margin: 0 auto;
}
.gallery_images img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    margin-bottom: 5%;
    border-radius: 6px;
}
.gallery_images img:hover {
    box-shadow: 0 0 10px #2b2b2b;
    transform: scale(1.01);
}

.preview_image {
    z-index:99999 !important;
}


